import React, { useEffect, useState } from 'react';
import './vacancy.scss';
import Header from '../../header/Header';
import VacancyInfo from './VacancyInfo/VacancyInfo';
import Footer from '../../footer/Footer';
import { AboutUsSection } from '../../about/AboutUsSection/AboutUsSection';
import { WhyUsVacancySection } from './whyUsVacancySection/WhyUsVacancySection';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useScrollPosition } from '../../../hooks/useScrollPosition';

export const Vacancy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [scrollPosition, setScrollPosition] = useState(0);
  useScrollPosition(setScrollPosition);

  return (
    <div className="vacancy__main-container">
      <Header />

      <VacancyInfo />

      <AboutUsSection
        title="Welcome to GamarTech, a software development company based in Poland."
        description="We are dedicated to creating innovative and cutting-edge 
        solutions for a diverse range of clients. 
        Our talented team of developers and designers 
        work collaboratively to bring our clients' visions 
        to life, delivering high-quality products that meet their 
        unique needs. We foster a supportive and inclusive work environment 
        that encourages creativity, growth, and continuous learning. As part of 
        our team, you'll have the opportunity to contribute to exciting projects 
        and be at the forefront of technology, making a meaningful impact in the 
        world of software development. Join us and be a part of our journey towards excellence!"
      />

      <WhyUsVacancySection />

      <Footer />

      <div
        className={`vacancy-fab ${scrollPosition > 900 && 'show'}`}
        onClick={() => window.scrollTo(0, 0)}
      >
        <KeyboardArrowUpIcon />
      </div>
    </div>
  );
};
