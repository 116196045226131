import worker_img1 from '../../assets/workers_img/sergei_image.png';
import worker_img2 from '../../assets/workers_img/alexey_image.png';
import worker_img3 from '../../assets/workers_img/vladislav_image.png';
import worker_img4 from '../../assets/workers_img/maxim_image.png';
import worker_img5 from '../../assets/workers_img/alexander_image.png';
import worker_img6 from '../../assets/workers_img/egor_image.png';
import worker_img7 from '../../assets/workers_img/anastasia_image.png';
import worker_img8 from '../../assets/workers_img/artem_image.png';

export interface ITalentsData {
  id: number;
  name: string;
  position: string;
  about: string;
  location: string;
  experience: string;
  salary: string;
  image: string;
  skills: string[];
  workHistory: any[];
}
export const talentsData = [
  {
    id: 1,
    name: 'Alexey Kynkurogov',
    position: 'Frontend Developer',
    location: 'Poland',
    experience: '3',
    salary: '40$/hr',
    image: worker_img2,
    skills: ['JavaScript', 'React', 'Redux', 'React Native', 'Angular', 'HTML', 'CSS', 'Vue'],
  },
  {
    id: 2,
    name: 'Vladislav Mankevich',
    position: 'Frontend Developer',
    location: 'Poland',
    experience: '3',
    salary: '40$/hr',
    image: worker_img3,
    skills: ['JavaScript', 'React', 'Redux', 'React Native', 'Angular', 'HTML', 'CSS', 'Vue'],
  },
  {
    id: 3,
    name: 'Maksim Skirukha',
    position: 'Frontend Developer',
    location: 'Poland',
    experience: '3',
    salary: '40$/hr',
    image: worker_img4,
    skills: ['JavaScript', 'React', 'Redux', 'React Native', 'Angular', 'HTML', 'CSS', 'Vue'],
  },
  {
    id: 4,
    name: 'Egor Voitehovsky',
    position: 'Backend Developer/Team Lead',
    location: 'Poland',
    experience: '6',
    salary: '70$/hr',
    image: worker_img6,
    skills: ['Ruby', 'Ruby on Rails', 'JavaScript', 'React', 'Angular', 'SQL', 'Team management'],
  },
  {
    id: 5,
    name: 'Anastasia Siniutsich',
    position: 'UI/UX Designer',
    location: 'Poland',
    experience: '4',
    salary: '50$/hr',
    image: worker_img7,
    skills: ['Figma', 'Adobe Photoshop', 'JavaScript', 'CSS', 'After Effects'],
  },
  {
    id: 6,
    name: 'Artem Rudakov',
    position: 'Frontend',
    location: 'Poland',
    experience: '5',
    salary: '55$/hr',
    image: worker_img8,
    skills: [
      'JavaScript',
      'Node.js',
      'React',
      'Redux',
      'React Native',
      'Angular',
      'HTML',
      'CSS',
      'Vue',
    ],
  },
];

export const job = [
  'Frontend',
  'Backend',
  'UI/UX',
  'Fullstack',
  'QA',
  'DevOps',
  'Project Manager',
  'Team Lead',
];

export const technologies = ['React', 'Angular', 'Ruby', 'Vue', 'Node.js'];
export const positions = ['Junior (<3 yrs)', 'Middle (3-5 yrs)', 'Senior (>5 yrs)'];
export const locations = ['Ukraine', 'Poland', 'Poland'];
