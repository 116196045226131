import React from 'react';
import './staticPages.scss';
import Header from '../header/Header';
import Footer from '../footer/Footer';

export const PrivacyPolicy = () => {
  return (
    <div className="main-container">
      <Header />

      <div className="content-frame">
        <div className="content-container">
          <h1>Privacy Policy</h1>

          <div className="section">
            <span className="section-title">Welcome to GamarTech!</span>
            <p>
              We are a software development company based in Poland, committed to delivering
              innovative software solutions. This Privacy Policy outlines how we collect, use,
              protect, and disclose information obtained from users of our website and services.
            </p>
          </div>

          <div className="additional-content-container">
            <div className="section">
              <span className="section-title">1. Information Collection</span>
              <p className="italic-text">
                We collect information to provide better services to all of our users. The types of
                information we gather include:
              </p>
              <ul>
                <li className="colored-item">
                  <p>
                    <b>Personal Information:</b> This includes your name, email address, phone
                    number, and any other information that you provide when you register on our
                    site, subscribe to our newsletter, or fill out a form.
                  </p>
                </li>
                <li className="colored-item">
                  <p>
                    <b>Usage Data:</b> We collect information about how you access and use our site,
                    including the type of browser you use, access times, pages viewed, your IP
                    address, and the page you visited before navigating to our site.
                  </p>
                </li>
              </ul>
            </div>

            <div className="section">
              <span className="section-title">2. Use of Information</span>
              <p className="italic-text">
                The information we collect from you may be used in one of the following ways:
              </p>
              <ul>
                <li>
                  To personalize your experience (your information helps us to better respond to
                  your individual needs).
                </li>
                <li>
                  To improve our website (we continually strive to improve our website offerings
                  based on the information and feedback we receive from you).
                </li>
                <li>
                  To improve customer service (your information helps us to more effectively respond
                  to your customer service requests and support needs).
                </li>
                <li>
                  To send periodic emails (the email address you provide may be used to send you
                  information, respond to inquiries, and/or other requests or questions).
                </li>
              </ul>
            </div>

            <div className="section">
              <span className="section-title">3. Information Protection</span>
              <p>
                We implement a variety of security measures to maintain the safety of your personal
                information when you enter, submit, or access your personal information.
              </p>
            </div>

            <div className="section">
              <span className="section-title">4. Information Sharing</span>
              <p>
                We do not sell, trade, or otherwise transfer to outside parties your personally
                identifiable information. This does not include trusted third parties who assist us
                in operating our website, conducting our business, or servicing you, so long as
                those parties agree to keep this information confidential. We may also release your
                information when we believe release is appropriate to comply with the law, enforce
                our site policies, or protect ours or others' rights, property, or safety.
              </p>
            </div>

            <div className="section">
              <span className="section-title">5. Third-party links</span>
              <p>
                Occasionally, at our discretion, we may include or offer third-party products or
                services on our website. These third-party sites have separate and independent
                privacy policies. We, therefore, have no responsibility or liability for the content
                and activities of these linked sites.
              </p>
            </div>

            <div className="section">
              <span className="section-title">6. Your Consent</span>

              <p>By using our site, you consent to our website's privacy policy.</p>
            </div>

            <div className="section">
              <span className="section-title">7. Changes to our Privacy Policy</span>

              <p>Any changes to our privacy policy will be posted on this page.</p>
            </div>

            <div className="section">
              <span className="section-title">Contact Us</span>
              <p>
                If there are any questions regarding this privacy policy, you may contact us using
                the email: <a className="link">sales@gamartech.com</a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
